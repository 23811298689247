import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import "./../../styles/products.css"

//import icons

//import components
import GetStartedBanner from "./../../components/get-started-footer"
import TrustedByCompanies from "./../../components/companies-trusted"

const HeadingSection = ({ data }) => {
  const [productName, setproductName] = useState("Status Page")
  const [title, settitle] = useState(
    "Share your service uptime with Odown's status page."
  )
  const [description, setdescription] = useState(
    "Communicate your site's performance to your users within fully customizable public status pages"
  )
  const [getStartedButton, setgetStartedButton] = useState({
    name: "Get started for free",
    link: "https://app.odown.io/signup",
  })

  return (
    <div className="u-features status-page">
      {/* <div className="container">
				<h1 className="u-features__heading-title">Share your service uptime with Odown's status page.</h1>
				<p className="u-features__heading-desc">Communicate your site's performance to your users within fully customizable public status pages.</p>
				<div className="image-cover" >
				<div className="image-wrapper">   
				<Img fadeIn={false} loading="eager" fluid={data.headerImage.childImageSharp.fluid} alt="Status page" />
				</div>
                </div>
				</div> 
			<div className="u-features__footer-cover"></div> */}
      <div className="container">
        <div className="hero-col hero--text">
          <div className="hero-text__wrapper">
            <h1 className="u-features__heading-title">{title}</h1>
            <p className="u-features__heading-desc">{description}</p>
            <div className="u-features__heading-btns">
              <OutboundLink
                className="btn btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blanc"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
        </div>
        <div className="hero-col hero--image">
          <div className="image-cover">
            <div className="image-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.headerImage.childImageSharp.fluid}
                alt={productName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const SubscribeToUpdates = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              status page's subscribing
            </span>
            <h2 className="u-main-feature_tc-title">
              Customers can subscribe in seconds
            </h2>
            <p className="u-main-feature_tc-desc">
              Now it's easy to offer status updates and real-time outage
              notifications available to your customers and team members who
              want to receive them. They can be alerted by email, Slack,
              Discord, SMS text messages, or RSS feed with one click.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.statusPage__customersCanSubscribe.childImageSharp.fluid
                }
                alt="Status pages - Customers can subscribe in seconds"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const TransparentDataCustomerConfidence = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Rich useful data</span>
            <h2 className="u-main-feature_tc-title">
              Transparent data that builds customer confidence
            </h2>
            {/* <p className="u-main-feature_tc-desc">Keep your users informed about your shared response time on your status page. Respond faster and gain credibility across the board. Odown keeps you honest, by automatically sharing your monitors' latest response times.</p> */}
            <p className="u-main-feature_tc-desc">
              Make your business completely transparent by keeping your
              customers informed about your shared response time. Odown also
              allows your users to see past issues and resolution times, letting
              them know exactly what's going on behind the scenes.
            </p>
            {/* <p className="u-main-feature_tc-desc small">Your users will see how you're striving to be as fast as possible.</p> */}
            <p className="u-main-feature_tc-desc small">
              Shared response time is suitable for some businesses, so we have
              made this feature optional.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.statusPage__responseTime.childImageSharp.fluid}
                alt="Status pages - Transparent data that builds customer confidence"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const CustomDomainSection = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Custom domain name</span>
            <h2 className="u-main-feature_tc-title">
              Use your own domain name
            </h2>
            <p className="u-main-feature_tc-desc">
              Set up a status page on your domain, such as status.example.com.
              An integrated status page on your business domain name will allow
              you to confidently inform your users about what is currently
              happening with your product or project.
            </p>
            <p className="u-main-feature_tc-desc small">
              Use the custom domain feature to make your business look more
              professional and make your status page the healthy face of your
              business.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.statusPage__customDomain.childImageSharp.fluid}
                alt="Status pages - Use your own domain name"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const CustomDesignSection = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">Custom design</span>
            <h2 className="u-main-feature_tc-title">
              Create a uniform brand for your pagee
            </h2>
            <p className="u-main-feature_tc-desc">
              Our status page is fully customizable; it allows you to play well
              with your brand, add a logo of your choice and change the color
              scheme to match your business brand.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.statusPage__customDesign.childImageSharp.fluid}
                alt="Status pages - Create a uniformed brand for your pagen"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/**
 *
 * @param {*} param0
 * @returns
 */
const AdaptedStatusPageForYourCompanyAndCustomers = ({ data }) => {
  return (
    <section className="u-main-feature  left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">status page</span>
            <h2 className="u-main-feature_tc-title">
              Status page made for your company
            </h2>
            <p className="u-main-feature_tc-desc">
              Our status page gives you a feature-rich and fully customizable
              status page to keep your customers informed if anything goes
              wrong. It's just a simple page designed for you that confidently
              represents your brand.
            </p>
            <p className="u-main-feature_tc-desc small">
              When your team is working late at night to solve an issue, your
              customers can subscribe to the status page and get the latest
              updates in their preferred method. As a result, they will trust
              you and feel comforted.
            </p>
            <p className="u-main-feature_tc-desc small">
              If your website goes down, it means that you'll lose money, maybe
              lots of it; this is why our Status page was born. It provides the
              detailed, up-to-date status of your websites and APIs. Your
              customers and support teams get a clear picture of what's
              happening—and you save money by solving issues before they become
              problems.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.statusPage__madeForYourCompany.childImageSharp.fluid
                }
                alt="Status pages - Status page made for your company"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const AllCommunicationInOnePlace = ({ data }) => {
  return (
    <section className="u-main-feature  right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <span className="u-main-feature_tc-header">
              Simple communication
            </span>
            <h2 className="u-main-feature_tc-title">
              An easy-to-use solution for real-time communication
            </h2>
            <p className="u-main-feature_tc-desc">
              Suppose technical issues impact your customers on your website. In
              that case, they might want to rely on your status page to help
              them judge whether they can do business with you that day or not.
            </p>
            <p className="u-main-feature_tc-desc small">
              By using our status page, your customers will see all the details
              and updates that matter most, such as whether your site is up or
              down, the root cause of the issue, and how long it'll take to get
              back online.
            </p>
            <p className="u-main-feature_tc-desc small">
              Status pages solve the problem of updating multiple places at once
              and communicating with both your users and your team by delivering
              real-time notifications in a friendly and persuasive way.
            </p>
            <p className="u-main-feature_tc-desc small">
              We help you provide transparent service and make your customers
              happier.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={
                  data.statusPage__allCommunicationInOnePlace.childImageSharp
                    .fluid
                }
                alt="Status pages - An easy-to-use solution for real-time communication"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} param0
 * @returns
 */
const SeparatorWhatExpectFromStatusPage = ({ data }) => {
  return (
    <section className="u-main-feature separator">
      <div className="container">
        <div className="u-main-feature__wrapper one-tab">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title">
              So what can I expect from our beautiful Status Pages?
            </h2>
            <p className="u-main-feature_tc-desc small">
              Our Status Pages provide everything you need to immediately and
              effectively keep your customers informed about your service status{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

const StatusPage = ({ data }) => (
  <Layout
    currentPage={`product-status_page`}
    navParams={{ textColor: "black" }}
  >
    <SEO
      title="Odown’s Status Page: Real-Time Service Uptime Updates"
      description="Boost user trust and transparency with a customizable public status page. Discover how to set up a status page that effectively communicates your site's performance and uptime."
      pathname={`/product/status-page/`}
      // meta={[
      // 	{
      // 		name : `twitter:image:src`,
      // 		content :  data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},
      // 	{
      // 		name : `og:image`,
      // 		content : data?.site?.siteMetadata.siteUrl + '/general-image-og--odown.png'
      // 	},

      // ]}
      image={{
        src: "/general-image-og--status-page.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection data={data} />
    <TrustedByCompanies direction="left" />
    <AdaptedStatusPageForYourCompanyAndCustomers data={data} />
    <AllCommunicationInOnePlace data={data} />

    <SeparatorWhatExpectFromStatusPage data={data} />

    <SubscribeToUpdates data={data} />
    <TransparentDataCustomerConfidence data={data} />
    <CustomDomainSection data={data} />
    <CustomDesignSection data={data} />
    <GetStartedBanner />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }

    headerImage: file(
      relativePath: { eq: "product__status-page__hero-image.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    statusPage__responseTime: file(
      relativePath: { eq: "product__status-page__option__responsetime.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    statusPage__customDomain: file(
      relativePath: { eq: "product__status-page__option__custom-domain.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    statusPage__customDesign: file(
      relativePath: { eq: "product__status-page__option__custom-design.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    statusPage__customersCanSubscribe: file(
      relativePath: {
        eq: "product__status-page__option__customes-can-subscribe.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    statusPage__madeForYourCompany: file(
      relativePath: {
        eq: "product__status-page__option__made-for-your-company.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    statusPage__allCommunicationInOnePlace: file(
      relativePath: {
        eq: "product__status-page__option__all-communication-in-one-place.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default StatusPage
